import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import BlogPost from './components/blog-post/BlogPost'
import BlogPosts from './components/blog-posts/BlogPosts';
import HomePage from './components/home-page/HomePage';
import { DataProvider } from './contexts/DataContext'

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>

          <Route path='/' element={ <HomePage /> } ></Route>
          <Route path='/blog' element={ <BlogPosts /> }></Route>
          <Route path='/blog/:id' element={ <BlogPost /> } /> 

        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;
