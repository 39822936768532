import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useData } from '../../contexts/DataContext'
import './blogpost.css'
import * as bp from './content-types/ContentTypes'
import { motion } from 'framer-motion'

export default function BlogPost() {
  
    const { id: idParam } = useParams()
    const navigate = useNavigate()
    const data = useData()
    const [currentArticle, setCurrentArticle] = useState<any>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const currentPost = data.find((article: any) => article.slug == idParam)
        if (!currentPost) {
            console.log('no article found')
            navigate('/')
        }
        setCurrentArticle(currentPost)
        setLoading(false)
    }, [idParam])
  
  
    if (loading) {
        return <></>
    }

    console.log(currentArticle)
    return (
        <>
        <section>
            <article className='container'>
                <img src={currentArticle.cover} alt="" className="bp-image bp-cover" />
                <header className='bp-header'>
                    <h1>{currentArticle.title}</h1>
                    <div className="bp-info">
                        {/* <div className="bp-author">
                            <div className='img' style={{ backgroundImage: `url(${currentArticle.author.profile_picture})` }} ></div>
                            <div>
                                <p className='bp-name'>{currentArticle.author.name}</p>
                                <p className='bp-muted'>{currentArticle.created} • {currentArticle.duration} min read</p>
                            </div>
                        </div> */}
                        <div className="bp-tags">
                            {currentArticle.tags.map((tag: string) => {
                                return (
                                    <div className="bp-tag">{tag}</div>
                                )
                            })}
                        </div>
                    </div>
                </header>
                {currentArticle.content.map((block: any, i: number) => {
                    if (['h1', 'h2', 'h3'].includes(block.type)) {
                        return <bp.BlogHeading block={block} key={i} />
                    }
                    else if (block.type == 'p') {
                        return <bp.BlogParagraph block={block} key={i} />
                    }
                    else if (block.type == 'code') {
                        return <bp.BlogCode block={block} key={i} />   
                    }
                    else if (block.type == 'image') {
                        return <bp.BlogImage block={block} key={i} />
                    }
                })}
            </article>
        </section>
        </>
  )
}
