import React from 'react'
import { Link } from 'react-router-dom'
import { useData } from '../../contexts/DataContext'
import { motion } from 'framer-motion' 
import './blogposts.css'

export default function BlogPosts() {
    
    const data = useData();

    console.log(data)
  
    return (
        <section>
            <div className="container bp-cards-wrapper">
                {data.map((post: any, i: number) => {
                    return <BlogCard post={post} key={i} />
                })}
            </div>
        </section>
    )
}


function BlogCard({ post }: { post: any }) {

    console.log(post)

    return (
        <Link to={post.slug} className="bp-card">
            {/* <motion.div layout layoutId={post.id}> */}
                <img src={post.cover} alt="" />
                <div className="bp-card-content">
                    <h1>{post.title}</h1>
                    <h2>{post.created} · {post.duration} min read</h2>
                </div>
                <div className="bp-card-tags">
                    {post.tags.map((tag: string, i: number) => {
                        if (i > 1) {
                            return
                        }
                        return <div key={i} className="bp-tag bp-mini-tag">{tag}</div>
                    })}
                </div>
            {/* </motion.div> */}
        </Link>
    )

}