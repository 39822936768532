import React, { useState, useEffect, useContext } from 'react'


const DataContext = React.createContext<any>({})

export function useData() {
    return useContext(DataContext)
}


export function DataProvider({ children }: { children: React.ReactNode }) {
    

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any>()

    const genRand = (len: number) => {
        return Math.random().toString(36).substring(2,len+2);
    }

    useEffect(() => {
        const controller = new AbortController();
        (async function() {
            const response = await fetch(`https://us-central1-snypemedia-62b10.cloudfunctions.net/getData?${genRand(8)}=${genRand(8)}`, { signal: controller.signal })
            const data = await response.json()
            setData(data)
            setLoading(false)
        })()
        return () => controller.abort()
    }, [])


    const value: any = data

    return (
        <DataContext.Provider value={value}>
            {loading ? <></> : children}
        </DataContext.Provider>
    )    
    

}

